/* customize Sweet Alert 2 */

.swal2-popup.swal2-modal {
  display: none;
  position: relative;
  box-sizing: border-box;
  grid-template-columns: minmax(0, 100%);
  width: 625px;
  max-width: 90%;
  min-height: 500px;
  padding: 60px;
  border: none;
  border-radius: 5px;
  background: #32363b;
  color: #fff;
  font-family: inherit;
  font-size: 1rem;
}

.swal2-title {
  padding: 0 !important;
  color: #ffffff !important;
  font-family: Yeseva One;
  font-style: normal;
  font-weight: normal !important;
  font-size: 58px !important;
  line-height: 65px;
}

.swal2-html-container {
  font-family: Inter;
  color: #fff !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 18px !important;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

body.swal2-shown.swal2-height-auto {
  height: 100vh !important;
}

.ava_bid_data {
  display: flex;
  flex-direction: column;
}

.ava_label {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
}

.ava_value {
  font-family: Inter;
  font-size: 14px;
  color: #afb3c2;
  font-weight: normal;
  padding: 8px 0;
}

.swal2-input {
  height: 60px !important;
  padding: 0 10px !important;
  margin: 48px 0 !important;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  font-size: 48px !important;
  font-family: Work Sans !important;
  font-weight: 700 !important;
  color: #fff !important;
  text-align: center;
}

.swal2-input.swal2-inputerror {
  border-color: transparent !important;
}

.swal2-styled.swal2-confirm {
  border-radius: 8px !important;
  background-color: #ff3242 !important;
  font-size: 18px !important;
  font-family: Work Sans;
  font-weight: 600 !important;
  padding: 10px 12px 14px !important;
  color: #ffffff !important;
  min-width: 146px !important;
}

.swal2-icon {
  border: none !important;
  margin: 70px auto 25px !important;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: 0 0 0 3px transparent !important;
}

#swal2-validation-message {
  font-family: Work Sans;
  background: none;
  color: #ff3242;
  font-size: 18px !important;
  font-weight: 600;
}
#swal2-validation-message::before {
  display: none;
}

@media screen and (max-width: 720px) {
  .swal2-popup.swal2-modal {
    min-height: 343px;
  }
  .swal2-title {
    font-size: 24px !important;
    line-height: 28px;
    padding-bottom: 16px !important;
  }
  .swal2-icon {
    margin: 35px auto 25px !important;
  }
  .swal2-input {
    height: 33px !important;
    font-size: 33px !important;
    margin: 16px 0 !important;
  }
}

.twitter-icon-bid {
  margin-right: 8px;
}

.swal2-styled.swal2-cancel {
  border-radius: 8px !important;
  background-color: transparent !important;
  font-size: 18px !important;
  font-family: Work Sans;
  font-weight: 600 !important;
  padding: 10px 12px 14px !important;
  color: #ffffff !important;
  min-width: 146px !important;
  border: 1px solid #ffffff !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
