@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400;600&family=Work+Sans:wght@600;700&family=Yeseva+One&display=swap');

body {
  margin: 0;
  height: 100%;
  background-color: #fff;
  font-family: 'Inter, ui-sans-serif, system-ui, -apple-system, system-ui, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}
